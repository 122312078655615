<template>
  <!-- Main content -->
  <section class="content">
    <!-- 搜索 -->
    <div class="btn-box">
      <el-form :inline="true">
        <el-form-item label="关键词：">
            <el-input v-model="search_key" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="平台：">
          <el-select v-model="search_platform" placeholder="">
            <el-option v-for="item in platformList" :label="item.name" :value="item.type"></el-option> 
          </el-select>
        </el-form-item>
        
        <el-form-item>
            <el-button type="primary" @click="seach(1)">搜索</el-button>
            <el-button type="" @click="reset(1)">重置</el-button>
        </el-form-item>
    </el-form>
    </div>
    <!-- 添加按钮 -->
    <div class="btn-box">
      <el-button type="primary" size="mini" @click="add">添加</el-button>
        <el-button type="danger" size="mini" @click="del()">删除</el-button>
    </div>
    <!-- 表单 -->
    <el-table 
        v-loading="loading"
        :data="informationList"
        border
        style="width: 100%;"
        @selection-change="handleSelectionChange">
         <el-table-column
        type="selection"
        width="55">
        </el-table-column>
        <el-table-column label="优惠券金额" prop="amount"></el-table-column>
        <el-table-column label="门槛" prop="doorsill_amount"></el-table-column>
        <el-table-column label="适合平台">
            <template slot-scope="item">
                {{item.row.platform | platform}}
            </template>
</el-table-column>
<el-table-column label="适用类目" prop="cate_name"></el-table-column>
<el-table-column label="使用场景" prop="scene"></el-table-column>
<el-table-column label="领取次数" prop="click_times"></el-table-column>
<el-table-column fixed="right" label="操作">
    <template slot-scope="item">
            <el-button type="primary" size="mini" @click="getCoupon(item.row.id)">修改</el-button>
            <el-button type="danger" size="mini" @click="del(item.row.id)">删除</el-button>
        </template>
</el-table-column>
</el-table>
<!-- 分页 -->
<el-pagination background layout="total, sizes,prev, pager, next,jumper" :total="total" @size-change="sizeChange" @current-change="currentChange" :current-page="page">
</el-pagination>

<couponsDialog :showExport="dialog" @clickClose="close" :platform="platform"></couponsDialog>
</section>
</template>
<script>
    let common = JSON.parse(localStorage.getItem('common'))
    import * as api from "@/config/api";
    export default {
        name: 'maintenanceCoupons',
        data() {
            return {
                loading: false,
                pagesize: 10,
                page: 1,
                total: 0,

                classifyList: common.act_community_gift_category,
                platformList: common.platform_list,

                dialog: false,
                informationList: [],


                search_key: '',
                search_platform: '0',

                select: [],
                platform: {}
            }
        },
        filters: {
            platform(val) {
                let name = "";
                if (JSON.stringify(common.platform_list).indexOf(val) != -1) {
                    common.platform_list.map((a, index) => {
                        if (val === a.type) {
                            name = a.name;
                        }
                    });
                }
                return name;
            }
        },
        mounted() {
            this.getCouponList()
        },
        methods: {
            handleSelectionChange(val) {
                this.select = []
                val.forEach(element => {
                    this.select.push(element.id)
                });
            },
            // 获取列表
            getCouponList(page, size) {
                api.getCouponList({
                    page: page ? page : this.page,
                    pagesize: size ? size : this.pagesize,
                    keyword: this.search_key,
                    platform: this.search_platform
                }, res => {
                    this.total = Number(res.data.count)
                    this.informationList = res.data.list
                    this.loading = false
                })
            },
            // 分页点击
            sizeChange(val) {
                this.pagesize = val
                this.getCouponList()
            },
            currentChange(val) {
                this.page = val
                this.getCouponList()
            },
            //搜索
            seach(page) {
                this.page = page
                this.getCouponList(page)
            },
            reset(page) {
                this.search_platform = '0'
                this.search_key = ''
                this.page = page
                this.getCouponList(page)
            },
            // 复制
            copy() {
                this.$message({
                    message: '复制成功',
                    type: 'success'
                })
            },
            // 获取详情
            getCoupon(id) {
                this.id = id
                api.getCoupon({
                    id: id
                }, res => {
                    this.platform = res.data
                    this.dialog = true
                })
            },
            close() {
                this.dialog = false
                this.getCouponList()
            },
            add() {
                this.platform = {}
                this.dialog = true
            },
            // 修改、添加频道分类
            alter() {
                if (this.id != '') {
                    api.updateActCommunity({
                        id: this.id,
                        item_id: this.item_id,
                        item_pic: this.item_pic,
                        item_title: this.item_title,
                        price: this.price,
                        end_price: this.end_price,
                        kl: this.kl,
                        invite_num: this.invite_num,
                        category_id: this.category_id,
                        weight: this.weight,
                        status: this.status,
                    }, res => {
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        })
                        this.dialog = false
                        this.getCouponList()
                    })
                    return
                }
                api.addActCommunity({
                    item_id: this.item_id,
                    item_pic: this.item_pic,
                    item_title: this.item_title,
                    price: this.price,
                    end_price: this.end_price,
                    kl: this.kl,
                    invite_num: this.invite_num,
                    category_id: this.category_id,
                    weight: this.weight,
                    status: this.status,
                }, res => {
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    })
                    this.dialog = false
                    this.getCouponList()
                })
            },
            // 上下架
            changeStatus(id, status) {
                api.updateActCommunityStatus({
                    id: id,
                    status: status == 1 ? 2 : 1
                }, res => {
                    this.$message({
                        type: 'success',
                        message: res.msg
                    })
                    this.getCouponList()
                })
            },
            // 删除
            del(id) {
                console.log(id)
                let del
                if (id == undefined && this.select.length == 0) {
                    this.$message.error('请选择要删除的优惠券')
                    return false
                } else if (id != undefined) {
                    del = id
                } else {
                    del = this.select
                }
                this.$confirm('是否确认删除 ' + name, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    api.delCoupon({
                        id: del
                    }, res => {
                        this.$message({
                            type: 'success',
                            message: '删除成功'
                        });
                        this.getCouponList()
                    })
                }).catch(() => {})
            },
        }
    }
</script>
<style lang="scss">
    .btn-box {
        text-align: left;
        margin-bottom: 20px;
    }
    
    .el-image img {
        height: 40px;
    }
    
    .el-image-viewer__canvas img {
        width: auto;
        height: auto;
    }
</style>